// import logo from './logo.svg';
import './App.css';
import ganesh from './images/ganesh.jpg'
import god from './images/god.jpg'
import top from './images/top.png'
import border from './images/border.png'

function App() {
  return (
    <div className="App">
      <div className='box'>
        <img className='border' src= {border} alt= 'not found'></img>
        <img className='top' src= {top} alt= 'not found'></img>
        <img className='god1' src= {ganesh} alt= 'not found'></img>
        <h2 className= 'name'>Mr. Pentakota Durga Rao & <br/> Mrs. Hymavathi</h2>
        <p className= 'content'>INVITE YOUR GRACIOUS PRESENCE ON
        <br/>THE OCCASION OF OUR NEW HOME'S</p>
        <h4 className= 'content1'>Sri Satyanarayana Pooja</h4>
        <h6 className='month'>SEPTEMBER</h6>
        <h2 className='date'>17</h2>
        <h6 className='day'>SATURDAY</h6>
        <h6 className='time'>AT 11:00 AM</h6>
        <h6 className='year'>2022</h6>
        <p className='venue'>VENUE</p>
        <h3 className='place'>Tirumala Nagar</h3>
        <h6 className='address'>Road no: 7, Beside Sri Raghavendra Nilayam, Tirumala Nagar, Near Tower Pole, Vadlapudi, Near Kurmanpalem 
        <br/>Mobile: +91 8500258846 / 9908846206</h6>
        <img className='god' src= {god} alt= 'not found'></img>
        <a className='a' href='https://goo.gl/maps/VD7B6huge1c3nN4N9'>Click Me For Location</a>

      </div>
    </div>
  );
}

export default App;
